import React, { useCallback, useEffect, useMemo } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import { api } from 'api';
import { useModal } from 'components/ContextModal';
import { useEventModal } from 'components/ContextModal/event';

import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { Text } from 'components/Text';
import { IS_BETA } from 'const';
import { ButtonScrollToTop } from 'layouts/LayoutApp/ButtonScrollToTop';
import { useOpenAppLinksModal } from 'layouts/LayoutApp/helpers';
import { Header } from 'layouts/LayoutMobile[Game]Feed/Header';
import { useText } from 'text';
import { dynamic } from 'utils/dynamic';
import { links } from 'utils/links';
import { useScrollRestoration, cookie } from 'utils/main';

import css from './LayoutMobile.module.css';

import type { Meta } from 'utils/meta';

const ModalClusterRegion = dynamic(() =>
  import('containers/ModalClusterRegion').then(mod => mod.ModalClusterRegion),
);
const ModalCommonSettings = dynamic(() =>
  import('containers/ModalCommonSettings').then(mod => mod.ModalCommonSettings),
);

export interface LayoutMobileProps {
  me: api.Maybe<api.User>;
  meta?: Meta;
  hideMotivation?: boolean;
  children: React.ReactNode;
  scrollable?: boolean;
  withHeader?: boolean;

  onLogoClick?: (event: React.MouseEvent) => Promise<void> | void;
  onProfileClick?: (event: React.MouseEvent) => Promise<void> | void;
}

export const LayoutMobile: React.FC<LayoutMobileProps> = ({
  me,
  meta,
  children,
  scrollable = true,
  hideMotivation = false,
  withHeader = true,
  onLogoClick,
  onProfileClick,
}) => {
  const modal = useModal();
  const onboardingModal = useOnboardingModal();
  const eventModal = useEventModal();

  const router = useRouter();
  const text = useText(state => state.welcome.motivation);

  useScrollRestoration(router);
  useOpenAppLinksModal();

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--mobile-height', `${window.innerHeight}px`);
    };

    window.addEventListener('resize', appHeight);
    appHeight();

    return () => window.removeEventListener('resize', appHeight);
  }, []);

  const handleLogoClick = useCallback(
    (event: React.MouseEvent) => {
      if (onLogoClick) {
        onLogoClick(event);
      }
    },
    [onLogoClick],
  );

  const handleRegionClick = useCallback(() => {
    modal.open(ModalClusterRegion.Component, {});
  }, []);
  const handleCommonSettingsClick = useCallback(() => {
    modal.open(ModalCommonSettings.Component, {});
  }, []);
  const handleRegionMouseOver = useCallback(() => {
    ModalClusterRegion.prefetch();
  }, []);

  const handleCreateEventClick = useCallback(() => {
    eventModal.open({});
  }, [me?.id]);

  const handleProfileClick = useCallback(
    (event: React.MouseEvent) => {
      if (onProfileClick) {
        onProfileClick(event);
      }

      if (!me?.username) {
        // set auth redirect cookie to current page
        cookie.set('authRedirect', window.location.pathname, 1);

        return onboardingModal.open({});
      }

      router.push(links.user.home(me?.username));
    },
    [onProfileClick, me?.username],
  );

  const Meta = useMemo(() => {
    const devQuery = IS_BETA ? '?dev=true' : '';

    return (
      <Head>
        {meta?.title || meta?.og?.title ? (
          <title>{meta.title || meta?.og?.title}</title>
        ) : null}
        {meta?.og?.description ? (
          <meta content={meta.og.description} name="description" />
        ) : null}
        {meta?.og?.title ? (
          <meta content={meta.og.title} property="og:title" />
        ) : null}
        {meta?.og?.description ? (
          <meta content={meta.og.description} property="og:description" />
        ) : null}
        {meta?.og?.image ? (
          <meta content={meta.og.image + devQuery} property="og:image" />
        ) : null}
        {meta?.og?.image ? (
          <meta content="image/png" property="og:image:type" />
        ) : null}
        {meta?.og?.image ? (
          <meta content="summary_large_image" name="twitter:card" />
        ) : null}
        {meta?.og?.image ? (
          <meta content={meta.og.image} name="twitter:image" />
        ) : null}
        {/*
         * Docs how to make:
         * https://developer.apple.com/documentation/webkit/promoting_apps_with_smart_app_banners

        
        <meta content="app-id=1665916911" name="apple-itunes-app" />
        <meta property="al:ios:url" content={iOSdeeplink} />
        <meta content="LFG" property="al:ios:app_name" />
        <meta content="1665916911" property="al:ios:app_store_id" />
         */}
      </Head>
    );
  }, [
    meta?.title,
    meta?.description,
    meta?.og?.title,
    meta?.og?.description,
    meta?.og?.image,
  ]);

  const renderMotivation = useCallback(() => {
    if (me) {
      return null;
    }

    if (hideMotivation) {
      return null;
    }

    return (
      <div className={css.loginMotivation}>
        <Text.R3Memo semibold className={css.loginMotivationText}>
          {text.title}
        </Text.R3Memo>
        <button className={css.loginMotivationButton} onClick={handleProfileClick}>
          <Text.R3Memo semibold className={css.loginButtonMotivationText}>
            {text.action}
          </Text.R3Memo>
        </button>
      </div>
    );
  }, [me]);

  return (
    <section
      className={css.container}
      style={
        !scrollable
          ? {
              overflow: 'hidden',
              height: 'var(--mobile-height)',
              maxHeight: '-webkit-fill-available',
            }
          : {}
      }
    >
      {Meta}
      {withHeader && (
        <Header
          me={me}
          onCommonSettingsClick={handleCommonSettingsClick}
          onCreateEventClick={handleCreateEventClick}
          onLogoClick={handleLogoClick}
          onProfileClick={handleProfileClick}
          onRegionClick={handleRegionClick}
          onRegionMouseOver={handleRegionMouseOver}
        />
      )}
      {children}
      {renderMotivation()}
      <ButtonScrollToTop />
    </section>
  );
};
