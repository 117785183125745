import React from 'react';

import cx from 'classnames';
import Link from 'next/link';

import css from './Logo.module.css';

type LogoProps = React.SVGProps<SVGSVGElement>;

export const Logo = React.memo<LogoProps>(props => (
  <svg
    fill="url(#paint0_linear_925_4914)"
    height={24}
    viewBox="0 0 39 24"
    width={39}
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M0 0H39V24H0V0ZM1.59836 1.59574V22.4043H14.9607V16.5319H7.48033V1.59574H1.59836ZM9.07869 1.59574V7.46809H16.2941V9.06383H9.07869V14.9362H16.559V22.4043H22.441V1.59574H9.07869ZM24.0393 1.59574V22.4043H37.4016V9.06383H31.5197V14.9362H32.8577V16.5319H28.5833V14.9362H29.9213V7.46809H37.4016V1.59574H24.0393Z"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_925_4914"
        x1="0"
        x2="39.0761"
        y1="0"
        y2="22.6136"
      >
        <stop stopColor="#24F24D" />
        <stop offset={0.1} stopColor="#26F24F" />
        <stop offset={0.1} stopColor="#2CF354" />
        <stop offset={0.2} stopColor="#36F35C" />
        <stop offset={0.27} stopColor="#45F467" />
        <stop offset={0.33} stopColor="#57F576" />
        <stop offset={0.4} stopColor="#6DF688" />
        <stop offset={0.47} stopColor="#85F89C" />
        <stop offset={0.53} stopColor="#9EF9B0" />
        <stop offset={0.6} stopColor="#B6FBC4" />
        <stop offset="0.666667" stopColor="#CCFCD6" />
        <stop offset="0.733333" stopColor="#DFFDE5" />
        <stop offset="0.8" stopColor="#EDFEF0" />
        <stop offset="0.866667" stopColor="#F7FFF9" />
        <stop offset="0.933333" stopColor="#FDFFFD" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
));

export const ArrowLogo = React.memo<LogoProps>(props => {
  return (
    <svg fill="none" height={24} viewBox="0 0 24 25" width={24} {...props}>
      <circle cx="12" cy="12.5" fill="#17E540" r="12" />
      <path
        d="M7.80029 16.7L16.2003 8.29999M16.2003 8.29999V15.5M16.2003 8.29999H9.00029"
        stroke="black"
        strokeWidth="1.2"
      />
    </svg>
  );
});

export const NewLogo = React.memo<LogoProps>(props => {
  return (
    <svg fill="none" height="34" viewBox="0 0 56 34" width="56" {...props}>
      <rect
        fill="#2ADF4E"
        height="31.9454"
        stroke="black"
        strokeWidth="1.52121"
        width="21.297"
        x="33.0364"
        y="1"
      />
      <rect
        fill="#2ADF4E"
        height="10.6485"
        stroke="black"
        strokeWidth="1.52121"
        width="21.3505"
        x="11.6755"
        y="11.6484"
      />
      <rect
        fill="#2ADF4E"
        height="10.6485"
        stroke="black"
        strokeWidth="1.52121"
        width="21.3505"
        x="11.6755"
        y="1"
      />
      <rect
        fill="#2ADF4E"
        height="31.9454"
        stroke="black"
        strokeWidth="1.52121"
        width="10.6752"
        x="22.3506"
        y="1"
      />
      <path
        clipRule="evenodd"
        d="M11.6752 1H1V22.2969V32.9454V32.9454H11.6752V32.9454H22.3505V22.2969H11.6752V1Z"
        fill="#2ADF4E"
        fillRule="evenodd"
      />
      <path
        d="M1 1V0.239394H0.239394V1H1ZM11.6752 1H12.4359V0.239394H11.6752V1ZM1 32.9454H0.239394V33.7061H1V32.9454ZM11.6752 32.9454V33.7061H12.4359V32.9454H11.6752ZM11.6752 32.9454V32.1848H10.9146V32.9454H11.6752ZM22.3505 32.9454V33.706H23.1111V32.9454H22.3505ZM22.3505 22.2969H23.1111V21.5363H22.3505V22.2969ZM11.6752 22.2969H10.9146V23.0575H11.6752V22.2969ZM1 1.76061H11.6752V0.239394H1V1.76061ZM1.76061 22.2969V1H0.239394V22.2969H1.76061ZM1.76061 32.9454V22.2969H0.239394V32.9454H1.76061ZM1.76061 32.9454V32.9454H0.239394V32.9454H1.76061ZM11.6752 32.1848H1V33.7061H11.6752V32.1848ZM10.9146 32.9454V32.9454H12.4359V32.9454H10.9146ZM22.3505 32.1848H11.6752V33.706H22.3505V32.1848ZM21.5899 22.2969V32.9454H23.1111V22.2969H21.5899ZM11.6752 23.0575H22.3505V21.5363H11.6752V23.0575ZM10.9146 1V22.2969H12.4359V1H10.9146Z"
        fill="black"
      />
      <rect fill="#2ADF4E" height="9.12727" width="7.62518" x="20.6729" y="12.4092" />
      <rect fill="#2ADF4E" height="9.12727" width="7.62518" x="20.6729" y="1.76074" />
      <rect fill="black" height="1.52121" width="12.1697" x="41.4033" y="11.6484" />
      <rect fill="black" height="1.52121" width="5.33333" x="41.4031" y="20.7759" />
      <rect fill="black" height="10.6485" width="1.52121" x="41.4033" y="11.6484" />
      <g style={{ mixBlendMode: 'color-dodge' }}>
        <rect
          fill="url(#paint0_linear_132:749)"
          height="32"
          width="53.3333"
          x="1"
          y="1"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_132:749"
          x1="0"
          x2="40"
          y1="0"
          y2="110"
        >
          <stop stopColor="#2ADF4E" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
});

interface LogoLinkProps {
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
}

export const LogoLink = React.memo<LogoLinkProps>(
  ({ onClick = () => undefined, className }) => {
    // const me = useMe();

    return (
      <Link
        className={cx(css.logo, className)}
        href={{ pathname: '/' }}
        onClick={event => {
          // filtersIndex(getDefaultFilters(me, lang.apiValue));
          onClick(event);
        }}
      >
        <Logo />
      </Link>
    );
  },
);
