import { useCallback, useMemo } from 'react';

import { useApp } from 'components/ContextApp';
import { dynamic } from 'utils/dynamic';

import { useModal } from './helpers';

import type { AppLinksProps } from 'containers/ModalMobileAppLinks/Container';

const ModalMobileAppLinks = dynamic(() =>
  import('containers/ModalMobileAppLinks').then(mod => mod.ModalMobileAppLinks),
);

interface AppLinksModalResponse {
  open: (props: AppLinksProps) => void;
  prefetch: () => void;
}

// Since onboarding modal on mobile is a separate page, use this hook
// to open onboarding
export function useAppLinksModal(): AppLinksModalResponse {
  const app = useApp();
  const modal = useModal();

  const handleOpenDesktop = useCallback((props: AppLinksProps) => {
    modal.open(ModalMobileAppLinks.Component, props);
  }, []);
  const handlePrefetchDesktop = useCallback(() => {
    ModalMobileAppLinks.prefetch();
  }, []);

  const result = useMemo<AppLinksModalResponse>(() => {
    return {
      open: handleOpenDesktop,
      prefetch: handlePrefetchDesktop,
    };
  }, [app.isMobile]);

  return result;
}
