import { useEffect } from 'react';

import { useAppLinksModal } from 'components/ContextModal/appLinks';
import { COOKIE_REGION_KEY } from 'const';
import { APP_LINKS_MODAL_KEY } from 'containers/ModalMobileAppLinks/consts';

import { cookie } from 'utils/main';

export const QUERY_USERNAME_SEARCH_KEY = 'user_name';

export const useOpenAppLinksModal = () => {
  const appLinksModal = useAppLinksModal();

  useEffect(() => {
    const cluster = cookie.get(COOKIE_REGION_KEY);
    const isAppLinksOpenStore = localStorage.getItem(APP_LINKS_MODAL_KEY);

    if (cluster !== 'ru') {
      return;
    }

    if (isAppLinksOpenStore !== 'true') {
      appLinksModal.open({});
    }
  }, []);
};
