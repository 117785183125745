import React from 'react';

import { api } from 'api';
import { AvatarUser } from 'components/Avatar';
import { Planet, Profile, Plus2 } from 'components/Icons/Icons';
import { List } from 'components/List';
import { LogoLink } from 'components/Logo';
import { ThreeDots } from 'components/MoreInfo';
import { Text } from 'components/Text';

import css from './Header.module.css';

interface HeaderProps {
  me: api.Maybe<api.User>;
  onLogoClick?: (event: React.MouseEvent) => void;
  onRegionMouseOver?: (event: React.MouseEvent) => void;
  onProfileClick?: (event: React.MouseEvent) => void;
  onRegionClick?: (event: React.MouseEvent) => void;
  onCreateEventClick?: (event: React.MouseEvent) => void;
  onCommonSettingsClick?: () => void;
}
export const Header = React.memo<HeaderProps>(
  ({
    me,
    onLogoClick,
    onProfileClick,
    onRegionClick,
    onCreateEventClick,
    onRegionMouseOver,
    onCommonSettingsClick,
  }) => {
    const renderUser = () => {
      if (!me) {
        return (
          <button className={css.headerButton} onClick={onProfileClick}>
            <Text.R2 semibold className={css.buttonLabel}>
              Login
            </Text.R2>
            <Profile className={css.buttonIcon} />
          </button>
        );
      }

      return (
        <button className={css.headerButton}>
          <AvatarUser className={css.userImg} size={32} user={me} />
        </button>
      );
    };

    return (
      <header className={css.container}>
        <span className={css.logoContainer}>
          <LogoLink className={css.logoIcon} onClick={onLogoClick} />
        </span>
        <ThreeDots
          className={css.headerThreeDots}
          dotWidth={3}
          onClick={onCommonSettingsClick}
        />

        {me && (
          <button className={css.headerButton} onClick={onCreateEventClick}>
            <div className={css.createEventIconContainer}>
              <Plus2 className={css.createEventIcon} />
            </div>
          </button>
        )}
        {renderUser()}
        {/* <button
          className={css.headerButton}
          onClick={onRegionClick}
          onMouseOver={onRegionMouseOver}
        >
          <Planet className={css.buttonIcon} />
        </button> */}
      </header>
    );
  },
);
